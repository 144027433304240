import React, { useEffect, useMemo, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import CardSection from './CardSection'
import Card from './Card'
import { CARD, TEAM as ROUTE_TEAM, TEAMS } from '../../_constants/routes'
import { DEMO_TEAM, TALENT, TEAM_PROFILE as TYPE_TEAM, SHARE_YOUR_NEEDS } from '../../_constants/cardTypes'
import Balloon, { BACKDROP_TYPE } from './Balloon'
import { Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import usePath from '../../hooks/usePath'
import { useNavigate } from 'react-router-dom'
import Page from '../_library/Page'
import useAuth from '../../hooks/useAuth'
import useFirestore from '../../hooks/useFirestore'
import { TEAM_COLLECTION } from '../../_constants/globals'
import useCards from '../../hooks/useCards'


const CardSkeleton = styled(Skeleton)(({ theme }) => ({
  position: 'relative',
  '& img': {
    width: '100%',
    borderRadius: 15,
  },
  [theme.breakpoints.down('md')]: {
    width: 200,
  },
}))

export const DashboardPage = () => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  const teamHooks = useFirestore(TEAM_COLLECTION)
  const dbTeams = teamHooks.getDocs()
  const navigate = useNavigate()
  const path = usePath()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  
  const [currentScrollElement, setCurrentScrollElement] = useState(null)
  const [hideBalloon, setHideBalloon] = useState(false)
  
  const scroll = useRef()
  
  const team = useMemo(() => dbTeams?.find(t => t.id === profile.currentTeamSelectedRef?.id), [dbTeams, profile.currentTeamSelectedRef])
  
  const { aboutYouCards, aboutTeamCards, campfireCards, oneToOneCards } = useCards()
  
  // Scroll to active card
  useEffect(() => {
    if (!currentScrollElement || !currentScrollElement.el) return
    scroll.current = currentScrollElement.el
    try {
      scroll.current.scrollIntoView({ block: 'center' })
    } catch (error) {
      console.log(error)
    }
  }, [currentScrollElement])
  
  return (
    <Page withTopBar sx={{ mb: 6 }}>
      <Typography variant='h1' fontSize={{ xs: '22px', md: '42px' }} color='primary.dark' fontWeight={900} mt={{ md: 4 }} textAlign='center'>
        {t('dashboard.page-title')}
      </Typography>
      <CardSection title={t('dashboard.section-title.about-you')}>
        {aboutYouCards
          ? aboutYouCards.map(card => (
            <Card
              title={t('dashboard.card-subtitle.' + card.type)}
              type={card.available ? card.type : 'ghost'}
              key={card.id}
              animated={card.available && !card.read}
              ref={el => {
                if (card.available && !card.read && !currentScrollElement && el) setCurrentScrollElement({ el, section: 'about-you' })
              }}
            />
          ))
          : <CardSkeleton variant='rectangular' />
        }
        {aboutYouCards && !aboutYouCards.some(elem => elem.read) &&
          <Balloon
            preset='firstCard'
            onClick={() => navigate(`${CARD}/${TALENT}`)}
            backdropType={BACKDROP_TYPE.noBackdrop}
            style={{ zIndex: '1' }}
            styleTitle={{ pt: { sm: '50px' }, fontSize: '2.15rem' }}
          />
        }
      </CardSection>
      <CardSection title={`${team?.name || 'À PROPOS DE VOTRE ÉQUIPE'}`} locked={path.lockTeamSection}>
        {aboutTeamCards && path.displayTeamSectionCards
          ? aboutTeamCards.map((card, key) => (card.type === 'talent-ext'
            ? <Card
              title={t('dashboard.card-subtitle.talent-ext', { firstname: card.user.firstname })}
              type={card.isGhost ? 'ghost' : card.type}
              key={card.id}
              photoUrl={card.user.photoUrl}
              memberId={card.userRef.id}
              animated={card.isAnimated}
              ref={el => {
                if (card.isAnimated && !currentScrollElement && el) setCurrentScrollElement({ el, section: 'about-team', card: card.type })
              }}
            />
            : <Card
              title={card.title}
              type={card.isGhost ? 'ghost' : card.type}
              key={card.type || key}
              animated={card.isAnimated}
              ref={el => {
                if (card.isAnimated && !currentScrollElement && el) setCurrentScrollElement({ el, section: 'about-team', card: card.type })
              }}
            />),
          )
          : <CardSkeleton variant='rectangular' />
        }
        {!profile.companyRef && aboutYouCards?.filter(card => card.read).length === 4 && aboutTeamCards?.some(card => card.rating) && !hideBalloon &&
          <Balloon
            preset='lockedCategory'
            onClick={() => navigate(`${CARD}/${DEMO_TEAM}`)}
            style={{ left: '75%', zIndex: '1', boxShadow: '0 0 10px #eee' }}
            backdropType={isSmUp ? BACKDROP_TYPE.noBackdrop : BACKDROP_TYPE.transparentGreen}
            onCTA={() => navigate(ROUTE_TEAM)}
            onClose={() => setHideBalloon(true)}
          />
        }
        {path.displayActivatedTeamBalloon &&
          <Balloon
            preset='activatedTeam'
            onClick={() => navigate(`${CARD}/${TYPE_TEAM}`)}
            backdropType={BACKDROP_TYPE.noBackdrop}
            style={{ zIndex: '1', left: '75%' }}
            styleTitle={{ paddingTop: 75, fontSize: '2.15rem' }}
          />
        }
        {path.displayInviteTeamBalloon &&
          <Balloon
            preset='inviteTeam'
            onClick={() => navigate(TEAMS)}
            backdropType={BACKDROP_TYPE.noBackdrop}
            style={{ zIndex: '1', left: '75%', boxShadow: '0 0 10px #eee' }}
            styleTitle={{ paddingTop: 75, fontSize: '2.15rem' }}
          />
        }
      </CardSection>
      {team?.managerRefs.some(ref => ref.id === profile.id) &&
        <CardSection id='campfireSection' title={t('dashboard.section-title.team-debriefing')} locked={path.lockTeamDebriefCards}>
          {oneToOneCards.map(card =>
            <Card
              title={card.subtitle}
              type={card.available || card.notReadable ? card.type : 'ghost'}
              key={card.id}
              animated={card.available && !card.read}
              ref={el => {if (card.available && !card.read && el && !currentScrollElement) setCurrentScrollElement({ el, section: 'one-to-one' })} }
            />,
          )}
          {campfireCards?.length
            ? [
              ...campfireCards.map(card => <Card
                id={card.id}
                title={card.template?.title.fr}
                type={(card.available || card.type === SHARE_YOUR_NEEDS) ? card.type : 'ghost'}
                key={card.id}
                animated={(card.available || card.type === SHARE_YOUR_NEEDS) && !card.read && !card.notReadable}
                backgroundUrl={card.template?.cardUrl}
                ref={el => {
                  if (card.available && !card.read && !card.notReadable && el &&
                    (!currentScrollElement || (currentScrollElement && currentScrollElement.section === 'one-to-one'))
                  ) setCurrentScrollElement({ el, section: 'team-debriefing' })} }
              />),
              campfireCards.length < 3 && <Card key='next-campfire' type='next-campfire' />,
            ]
            : [<Card key={0} type='ghost-campfire' />]
          }
        </CardSection>
      }
    </Page>
  )
}

export default DashboardPage
