import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material'
import { CARD } from '../../_constants/routes'
import { DEMO_TEAM, TALENT_EXT } from '../../_constants/cardTypes'
import ShadowImage from 'react-shadow-image'
import photoIcon from '../../assets/DIVERS/avatar_team_member.svg'
import { motion } from 'framer-motion'
import { getImage } from '../../_helpers/cardHelper'
import anais from '../../assets/DIVERS/anais-intercom.jpg'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'


// eslint-disable-next-line react/display-name
const MyCard = forwardRef(({ id, title, type, photoUrl, memberId, animated, backgroundUrl }, ref) => {
  
  const { t } = useTranslation()
  const profile = useAuth().getProfile()
  
  const getCardBack = () => {
    switch (type) {
      case TALENT_EXT:
        return <Link to={`${CARD}/${TALENT_EXT}/${memberId}`}>
          <motion.div
            animate={{
              x: 0,
              y: animated ? -25 : 0,
              scale: 1,
              rotate: 0,
            }}
            initial={animated}
            transition={{
              duration: animated ? 1 : 0,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          >
            <Box sx={{ position: 'relative', width: { xs: 200, sm: 250 } }}>
              <ShadowImage src={getImage(TALENT_EXT)} alt={`card ${TALENT_EXT}`} />
              <Box sx={{
                top: '29%',
                left: '19%',
                width: '62%',
                height: '39.5%',
                overflow: 'hidden',
                borderRadius: 100,
                backgroundSize: 'cover',
                position: 'absolute',
                backgroundImage: `url('${photoUrl || photoIcon}')`,
              }} />
            </Box>
          </motion.div>
        </Link>
      case 'ghost':
        return <Box sx={{ position: 'relative', width: { xs: 200, sm: 250 } }}>
          <Box component='img' src={getImage()} alt='rectangle' sx={{ width: '100%', borderRadius: '15px' }} />
        </Box>
      case 'ghost-teamProfile':
        return <Box sx={{ position: 'relative', width: { xs: 200, sm: 250 } }}>
          <Box component='img' src={getImage(DEMO_TEAM)} alt='rectangle' sx={{ width: '100%', borderRadius: '15px' }} />
        </Box>
      case 'ghost-campfire':
        return <Paper elevation={0} sx={{
          bgcolor: 'grey.150',
          borderRadius: '15px',
          p: 3,
          width: { xs: '200px', sm: '250px' },
          height: { xs: '315px', sm: '395px' },
        }}>
          <Stack spacing={2} alignItems='center' justifyContent='space-between'>
            <Typography sx={{ fontSize: { xs: '1rem', sm: '1.5rem' }, color: 'grey.500', fontWeight: 600 }}>{t('dashboard.campfire.card-title')}</Typography>
            <Typography sx={{ fontSize: { xs: '.8rem', sm: '1rem' }, color: 'grey.500' }}>{t('dashboard.campfire.p1', { context: profile.gender })}</Typography>
            <Typography sx={{ fontSize: { xs: '.8rem', sm: '1rem' }, color: 'grey.500' }}>{t('dashboard.campfire.p2')}</Typography>
            <Avatar src={profile.consultantUser?.photoUrl || anais} sx={{ mx: 'auto', width: 60, height: 'auto', opacity: .7 }} />
          </Stack>
        </Paper>
      case 'next-campfire':
        return <Paper elevation={0} sx={{
          bgcolor: 'grey.150',
          borderRadius: '15px',
          p: 3,
          width: { xs: '200px', sm: '250px' },
          height: { xs: '315px', sm: '395px' },
        }}>
          <Stack spacing={3} alignItems='center' justifyContent='space-between'>
            <Typography sx={{ fontSize: { xs: '1rem', sm: '1.5rem' }, color: 'grey.500', fontWeight: 600 }}>{t('dashboard.campfire.next-card-title')}</Typography>
            <Typography sx={{ fontSize: { xs: '.8rem', sm: '1rem' }, color: 'grey.500' }}>{t('dashboard.campfire.next-p1')}</Typography>
            <Avatar src={profile.consultantUser?.photoUrl || anais} sx={{ mx: 'auto', width: 60, height: 'auto', opacity: .8 }} />
          </Stack>
        </Paper>
      default:
        return <Link to={id ? `campfire/${id}` : `${CARD}/${type}`} >
          <Box sx={{ position: 'relative', width: { xs: 200, sm: 250 } }}>
            {animated
              ? <motion.div
                animate={{
                  x: 0,
                  y: -25,
                  scale: 1,
                  rotate: 0,
                }}
                initial={!!animated}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: 'reverse',
                }}
              >
                <ShadowImage src={backgroundUrl || getImage(type)} alt='card' />
              </motion.div>
              : <ShadowImage src={backgroundUrl || getImage(type)} alt='card' />
            }
          </Box>
        </Link>
    }
  }
  
  return (
    <Grid item md={3} ref={ref} sx={{
      height: '100%',
      width: '100%',
      textAlign: 'center',
      p: { xs: 2, md: 1 },
      borderRadius: '20px',
    }}>
      {getCardBack()}
      <Typography sx={{
        fontSize: { lg: '1.3rem' },
        fontWeight: 'bold',
        mx: 'auto',
        maxWidth: '90%',
        mb: 0,
        mt: 1,
        color: type?.includes('ghost') ? '#C8C8C8' : 'primary.deepBlue',
      }}>{title}</Typography>
    </Grid>
  )
})

MyCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  photoUrl: PropTypes.string,
  memberId: PropTypes.string,
  animated: PropTypes.bool,
  backgroundUrl: PropTypes.string,
}

export default MyCard
