import React, { useEffect, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import CTAButton from '../_library/FirstLevelButton'
import LoadingBoussole from '../_library/LoadingBoussole'
import { CAMPFIRE_COLLECTION } from '../../_constants/globals'
import CardHeader from '../cards/CardHeader'
import Page from '../_library/Page'
import useFirestore from '../../hooks/useFirestore'
import { useMatch } from 'react-router-dom'
import { CAMPFIRE } from '../../_constants/routes'
import { useTranslation } from 'react-i18next'
import SquaredBalloon from '../_library/SquaredBalloon'
import Avatar from '../_library/Avatar'


/**
 * Camp fire 1
 */
export const CampfirePage = () => {
  
  const campfireHooks = useFirestore(CAMPFIRE_COLLECTION)
  const dbCampfires = campfireHooks.getDocs()
  const match = useMatch(CAMPFIRE)
  const { t } = useTranslation()
  
  const campfire = useMemo(() => {
    if (dbCampfires && match?.params.id) {
      const result = dbCampfires.find(c => c.id === match.params.id)
      console.info('campfire', result)
      !result.read && campfireHooks.updateDoc(match.params.id, { read: true })
      return result
    }
  }, [dbCampfires, match?.params.id])
  
  useEffect(() => { window.scrollTo(0, 0) }, [])
  
  if (!campfire) return <LoadingBoussole />
  else return (
    <Page withTopBar>
      <CardHeader title={campfire.template.title.fr} subtitle={t('campfire.page-subtitle-team')} backgroundUrl={campfire.template.cardUrl} />
      <Box sx={{
        mt: { md: 3, sm: 0 },
        mb: '100px',
        mx: { md: 'auto', sm: 1 },
        pl: { md: '11%', sm: 0 },
        textAlign: 'left',
        maxWidth: { md: '45vw', sm: '100%' },
        position: 'relative',
        color: 'black',
        fontSize: '1rem',
        lineHeight: '2rem',
      }}>
        <Typography fontWeight='bold' lineHeight='1.5rem'>
          {t('campfire.p1')}
        </Typography>
        <Typography variant='h3' fontSize='1.5rem' fontWeight='700' sx={{ color: 'tertiary.dark', mt: 4, mb: 2 }}>
          {t('campfire.file1-title')}
        </Typography>
        <Typography variant='body1'>{t('campfire.file1-body')}</Typography>
        <CTAButton
          component='a'
          href={campfire.spreadsheetUrl}
          target='_blank'
          rel='noopener noreferrer'
          download
          sx={{ my: 2 }}
        >
          {t('common.download')}
        </CTAButton>
        <Typography variant='h3' fontSize='1.5rem' fontWeight='700' sx={{ color: 'tertiary.dark', mt: 4, mb: 2 }}>
          {t('campfire.file2-title')}
        </Typography>
        <Typography variant='body1'>{t('campfire.file2-body')}</Typography>
        <CTAButton
          component='a'
          href={campfire.debriefUrl}
          target='_blank'
          rel='noopener noreferrer'
          download
          sx={{ my: 2 }}
        >
          {t('common.download')}
        </CTAButton>
        <SquaredBalloon avatar={<Avatar photoUrl={campfire.consultantUser.photoUrl} />} sx={{ mt: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: campfire.comment?.replaceAll('\n', '<br/>') }} />
        </SquaredBalloon>
      </Box>
    </Page>
  )
}

export default CampfirePage
